import * as React from 'react';
import initializeComponent, { createComponent } from 'Shared/react-initialize';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@dozuki/web-js/theme';

export function Initializer(Component: any) {
   return (props: any) => (
      <ChakraProvider theme={theme} resetCSS={false}>
         <Component {...props} />
      </ChakraProvider>
   );
}

export function initializeChakraComponent(name: any, Component: any, defaultProps = {}) {
   initializeComponent(name, Initializer(Component), defaultProps);
}

export function createChakraComponent(Component: any, props = {}) {
   return createComponent(Initializer(Component), props);
}
